// prevents default navigation, and put smooth scrolling instead
const scrollHREFNavigation = (event) => {
  event.preventDefault()

  document.querySelector(event.currentTarget.getAttribute('href')).scrollIntoView({
    behavior: 'smooth'
  })
}

export {
  scrollHREFNavigation
}
