import React from 'react'
import styled from 'styled-components'

import Header from './header'
import Main from './main'
import Footer from './footer'

import AppStoreButton from '~/app/partials/buttons/app-store'

const App = styled.div`
`

export default () => (
  <App>
    <Header />
    <Main />
    <Footer />
  </App>
)
