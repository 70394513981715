import React from 'react'
import styled from 'styled-components'

import { media } from '~/helpers';
// import { Button } from 'components/button/style';

// import { scrollHREFNavigation } from '../../helpers/scroll';
import AppStoreButton from '~/app/partials/buttons/app-store'

import Features from './partials/features';
import DownloadSection from './partials/download-section';

const Main = styled.div`
  text-align: center;
`

const Title = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 96px;
  // line-height: 112px;
  color: white;

  ${media.tablet`
    font-size: 76px;
    line-height: 92px;
  `}

  ${media.phone`
    margin: 40px;

    font-size: 52px;
    line-height: 61px;
  `}
`

const ButtonGroup = styled.div`
  margin-top: 40px;

  ${media.phone`
    margin-top: 51px;
  `}
`

const ShareDiv = styled.div`
  padding: 20px;
`

const FeaturesContainer = styled.div`
  margin-top: 50px;

  ${media.phone`
    margin-top: 126px;
  `}
`

const DownloadSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px 0 90px 0;

  ${media.phone`
    margin-top: 50px;
  `}
`

const Horizontal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.phone`
    flex-direction: column;
  `}
`

const ProducthuntButton = () => (
  <a href="https://www.producthunt.com/posts/feetime?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-feetime" target="_blank">
    <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=163257&theme=dark" alt="FeeTime - Полегшіть свій контроль податків | Product Hunt Embed" style={{ width: '250px', height: '54px' }} width="250px" height="54px" />
  </a>
)

export default () => (
  <Main>
    <Title>Полегшіть сплату<br/> податків</Title>
    <ButtonGroup>
      {/* <Button as='a' href='#download' onClick={scrollHREFNavigation}>Завантажити додаток</Button> */}
      <Horizontal>
        <AppStoreButton imgProps={{ width: '210' }} />
        <ProducthuntButton />
      </Horizontal>
      <ShareDiv>
        <div className="addthis_inline_share_toolbox" />
      </ShareDiv>
    </ButtonGroup>

    <FeaturesContainer id='features'>
      <Features />
    </FeaturesContainer>
    <DownloadSectionContainer id='download'>
      <DownloadSection />
    </DownloadSectionContainer>
  </Main>
)
