import React from 'react'

const SvgComponent = props => (
  <svg width={241} height={60} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.494 18.032a30.235 30.235 0 00-1.465 4.205c-4.287 16 5.208 32.446 21.208 36.734 16 4.287 32.446-5.208 36.734-21.208a29.866 29.866 0 00-1.49-19.781 632.145 632.145 0 01-9.648 8.122c-4.907 4.041-10.042 8.11-14.253 11.078-2.083 1.468-4.1 2.78-5.83 3.684-.848.443-1.836.9-2.856 1.194-.7.202-2.854.786-5.11-.256-1.118-.515-1.967-1.27-2.447-1.726a21.025 21.025 0 01-1.62-1.758c-1.02-1.223-2.14-2.755-3.28-4.409-2.291-3.325-4.914-7.513-7.363-11.562a499.288 499.288 0 01-2.58-4.317zm48.419-9.538a633.56 633.56 0 01-10.376 8.75C35.69 21.238 30.821 25.088 26.97 27.8a60.754 60.754 0 01-3.242 2.155 67.594 67.594 0 01-1.84-2.556c-2.098-3.045-4.582-7.005-6.992-10.99a545.736 545.736 0 01-5.083-8.595c7.3-6.63 17.717-9.527 27.95-6.785a29.856 29.856 0 0113.15 7.465zM25.323 31.83z"
      fill="#fff"
    />
    <path
      d="M99.041 32.07h-7.738V40h-4.8V20.094H99.82v3.705h-8.517v4.58h7.738v3.691zm22.121-.506h-7.547v4.745h8.914V40h-13.713V20.094h13.741v3.705h-8.942v4.197h7.547v3.569zm22.668 0h-7.547v4.745h8.914V40h-13.713V20.094h13.741v3.705h-8.942v4.197h7.547v3.569zm26.223-7.765h-5.975V40h-4.799V23.799h-5.865v-3.705h16.639v3.705zM184.203 40h-4.785V20.094h4.785V40zm16.639-19.906l4.443 13.85 4.43-13.85h6.316V40h-4.812v-4.648l.465-9.516L206.871 40h-3.172l-4.826-14.178.465 9.53V40h-4.799V20.094h6.303zm37.597 11.47h-7.546v4.745h8.914V40h-13.713V20.094h13.74v3.705h-8.941v4.197h7.546v3.569z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
