import React from 'react'

const SvgComponent = props => (
  <svg width={60} height={50} fill="none" {...props}>
    <path
      d="M59.046 5.852a23.757 23.757 0 0 1-4.137 1.395 12.9 12.9 0 0 0 3.405-5.423.676.676 0 0 0-.99-.787A23.871 23.871 0 0 1 51 3.65c-.128.032-.261.049-.395.049-.404 0-.798-.15-1.107-.423a12.935 12.935 0 0 0-8.559-3.23 13.83 13.83 0 0 0-4.098.632 12.532 12.532 0 0 0-8.446 9.03 13.8 13.8 0 0 0-.307 4.763.48.48 0 0 1-.52.522c-9.18-.853-17.46-5.276-23.312-12.454a.676.676 0 0 0-1.108.086 12.925 12.925 0 0 0-1.752 6.503c0 3.507 1.409 6.812 3.856 9.221a11.592 11.592 0 0 1-2.95-1.142.676.676 0 0 0-1.005.583 12.952 12.952 0 0 0 7.555 11.932 11.552 11.552 0 0 1-2.455-.205.676.676 0 0 0-.771.871 12.964 12.964 0 0 0 10.24 8.818 23.87 23.87 0 0 1-13.37 4.06L1 43.265c-.461 0-.851.3-.969.747-.115.44.098.904.49 1.132a35.503 35.503 0 0 0 17.841 4.81c5.484 0 10.613-1.088 15.246-3.234 4.247-1.967 8-4.78 11.155-8.359 2.939-3.334 5.237-7.183 6.83-11.44 1.52-4.057 2.322-8.386 2.322-12.52v-.197c0-.663.3-1.288.824-1.713a25.565 25.565 0 0 0 5.143-5.645c.377-.563-.217-1.27-.836-.994z"
      fill="#1DA1F2"
    />
  </svg>
)

export default SvgComponent
