import React from 'react'

const SvgComponent = props => (
  <svg viewBox="-10 -20 500 420" fill="none" {...props}>
    <mask
      id="prefix__a"
      maskUnits="userSpaceOnUse"
      x={147}
      y={33}
      width={189}
      height={334}
    >
      <rect
        x={147.811}
        y={33.081}
        width={187.813}
        height={332.941}
        rx={8}
        fill="#C4C4C4"
      />
    </mask>
    <g mask="url(#prefix__a)">
      <path
        d="M345.026 180.081c-.453-3.923-.151-7.997.453-11.92.755-4.979-.755-10.108-4.229-14.182-.302-.453-.755-.755-1.057-1.056-2.417-2.414-3.625-5.734-3.625-9.204 0-2.112-.453-4.677-2.114-7.242-1.36-1.811-2.87-3.32-4.38-4.225-4.078-2.565-6.646-6.639-7.703-11.316-1.057-4.375-4.078-8.6-12.082-9.053-2.87-3.168-6.193-5.28-10.421-4.375-5.135 1.207-9.062 8.147-11.781 12.976-2.869 5.28-5.89 15.691-.151 21.575 2.87 2.867 4.229 10.713 2.266 14.334-6.646 11.618 4.531 30.628 1.661 40.134-1.208 4.375-1.661 8.902.151 11.919 3.172 5.281 8.76 7.544 14.499 6.337 1.359-.302 2.718-.151 3.927.453 10.723 4.828 24.618-3.018 31.112-17.502 3.021-5.885 4.078-12.071 3.474-17.653z"
        fill="#2F2F2F"
      />
      <path
        d="M195.657 277.096c.906-2.263 1.511-4.526 1.964-5.281.151-.301.906-1.056 1.208-1.358.604-.754 1.661-1.659 1.812-2.715l.604-3.923c.302-1.811 1.36-3.169 3.323-2.867 1.963.302 2.719 2.112 2.114 4.225l-.453 2.715c-.453 1.66-.151 3.169.151 4.829.454 3.319-.906 6.94-2.265 9.807-.906 1.81-3.323 2.565-6.796 3.319-1.662.453-4.38.604-3.172-1.207 1.057-1.509 6.192-3.168 5.437-8.298 0-.302-.151-.906-.453-.906-.906-.15-1.359 1.811-1.963 2.867-.907 1.358-1.964 1.811-2.568 1.509-.453-.302.453-1.056 1.057-2.716z"
        fill="#FAB9AF"
      />
      <path
        d="M197.47 150.66c10.119 14.635 26.732 44.962 23.258 67.594-2.416 15.54-13.895 48.432-13.895 48.432s-1.812 0-3.171-.151c-1.511-.151-3.021-.905-3.021-.905s8.609-45.415 7.25-53.562c-1.511-8.148-20.994-46.622-22.353-54.769-1.963-9.204 6.343-14.937 11.932-6.639z"
        fill="#D29370"
      />
      <path
        d="M202.907 237.867l-12.989-91.432-35.19-4.829-18.274 95.658 66.453.603z"
        fill="#FAB9AF"
      />
      <path
        d="M163.79 238.773l38.966-3.319s3.927 28.365-5.89 79.664c-4.682 23.99-14.197 70.008-14.197 70.008-2.416 1.056-4.833 1.358-7.249.754 0 0-.756-20.217-1.209-42.095-.453-20.972-.604-43.302-1.812-50.544-2.87-15.088-8.609-54.468-8.609-54.468z"
        fill="#007B40"
      />
      <path
        d="M175.118 380.75c.604-4.526 2.567-40.586 5.739-52.657 4.531-16.295-8.458-34.702-4.38-46.47 11.629-34.703 2.869-42.397 2.869-42.397l-15.556-.453s5.739 39.379 8.458 54.316c1.359 7.394 1.51 29.724 1.812 50.545.302 15.239.755 29.723 1.058 37.116z"
        fill="#005A2F"
      />
      <path
        d="M171.795 239.226c4.078 21.576 12.989 53.864-4.229 84.04-17.368 30.326-35.492 61.709-35.492 61.709-2.417.151-4.531-.754-6.494-2.263 0 0 3.02-13.73 7.4-29.723 4.984-18.407 11.327-39.983 9.515-51.148-3.323-21.123-7.25-49.187-6.041-62.766 1.208-13.579 33.226-11.165 35.341.151z"
        fill="#007B40"
      />
      <path
        d="M202.303 239.225c1.359-.603 2.114-2.112 1.812-3.621l-14.348-89.169-35.19-4.829-18.728 94.149c-.302 1.811.755 3.47 2.568 3.772 6.494 1.207 22.352 3.772 40.023 3.772 13.14 0 20.54-2.414 23.863-4.074z"
        fill="#fff"
      />
      <path
        d="M162.582 165.144c-4.229 11.618-11.176 34.401-7.25 54.619 5.588 28.365 12.536 39.832 3.474 46.32-9.213 6.638-26.883-4.376-27.337-8.148-.453-3.621 3.474-48.734 7.854-68.348 4.531-19.615 16.916-47.678 16.916-47.678l6.494.603c.151.151 1.963 17.201-.151 22.632z"
        fill="#D29370"
      />
      <path
        d="M157.748 168.464c4.531-9.958 3.171-19.615 3.171-19.615-2.265-2.866-6.947-2.263-8.306 1.207-3.927 9.657-10.27 26.555-13.291 39.682-4.38 19.614 16.009-15.843 18.426-21.274zM183.726 145.53s2.718 12.673.604 31.684c-2.114 18.86-7.098 76.496 10.119 85.247 5.437 2.716 17.973-11.467 17.973-14.032 0-4.526-4.229-19.614-8.156-33.495-3.172-11.014-6.343-47.98-7.552-62.162-.302-3.32-2.718-5.885-6.041-6.337l-6.947-.905z"
        fill="#BD7E5B"
      />
      <path
        d="M158.202 106.904s.605 5.28-2.567 9.203c-3.172 3.923 1.51 7.544 2.567 10.109 1.058 2.565-.302 6.488 2.719 8.299 3.021 1.81 11.629 2.263 12.989-.906 1.51-3.168-5.891-28.516-15.708-26.705z"
        fill="#2F2F2F"
      />
      <path
        d="M193.09 113.844c.454 17.351-15.707 20.671-25.071 20.671s-9.817-20.822-9.817-28.215c0-7.544 7.401-8.6 16.765-8.6 7.4 0 14.348 5.734 17.217 11.165.906 1.509.755 3.47.906 4.979z"
        fill="#2F2F2F"
      />
      <path
        d="M175.42 104.339c-7.854 0-10.723 5.583-10.874 15.842 0 3.923 1.661 7.695 3.02 10.109a7.629 7.629 0 01.756 6.036l-1.662 5.884c-1.208 5.431 1.359 8.298 5.135 9.505 1.813.604 4.078.151 5.739-.905 1.964-1.207 2.115-3.923 1.813-6.186l-.453-1.811c-.604-2.263.755-4.677 3.02-5.13 6.042-1.357 6.948-7.544 8.458-17.351 1.661-10.259-7.098-15.993-14.952-15.993z"
        fill="#FAB9AF"
      />
      <path
        d="M193.694 99.813l-4.53 1.358a9.871 9.871 0 01-5.135 0l-8.911-2.565c-5.437 0-14.499 3.018-13.291 10.863l1.057 5.583c.453 2.263 2.266 3.923 4.531 4.074.151.754.906 1.357 1.661 1.357.907 0 1.813-.754 1.813-1.81a5.193 5.193 0 014.229-5.13c3.02-.604 6.192-.905 9.515 0 6.192 1.66 12.837-2.263 13.441-8.6v-1.207c0-2.716-2.114-4.526-4.38-3.923z"
        fill="#2F2F2F"
      />
      <path
        d="M168.774 120.332c0 2.414-.906 4.376-3.172 4.376-2.265 0-3.776-3.32-3.776-5.734s1.511-3.017 3.776-3.017c2.266 0 3.172 2.112 3.172 4.375z"
        fill="#FAB9AF"
      />
      <path
        d="M144.458 143.266c-14.046 7.544-27.035 18.408-47.877 43.454-1.51 1.81-1.51 4.526.151 6.186 4.531 4.526 14.801 13.579 34.586 23.235 1.662-2.414 2.568-4.677 2.719-6.94 0 0-19.785-19.765-20.993-20.822 0 0 33.226-19.614 41.382-26.705 8.156-7.092 7.703-14.937 5.286-18.106-2.416-3.168-11.478-2.263-15.254-.302z"
        fill="#D29370"
      />
      <path
        d="M173.013 156.058c-.453 0-.906.302-1.057.755-1.209 3.922-6.646 21.575-7.854 31.081-1.057 7.845 2.114 21.424 3.927 28.516.755 2.716 2.567 5.13 4.984 6.488l1.208.754c1.057.604 2.417.302 3.021-.754l1.057-1.811c.755-1.358.906-2.867.302-4.375-2.114-5.583-7.4-20.218-6.947-29.875.151-3.47 3.473-24.291 4.379-29.572.152-.603-.453-1.207-1.057-1.207h-1.963z"
        fill="#C8E6EB"
      />
      <path
        d="M176.023 157.298h-3.322a1.032 1.032 0 01-1.057-1.056v-3.32c0-.603.453-1.056 1.057-1.056l3.02-.302c.604 0 1.36.755 1.36 1.358v3.32c0 .603-.453 1.056-1.058 1.056z"
        fill="#C8E6EB"
      />
      <path
        d="M166.81 141.455l-5.739 1.056s1.208 9.204 5.739 15.994c.302.452 1.057.452 1.36 0l4.077-5.583c.453-.603.453-1.509.151-2.263l-5.588-9.204zM179.195 143.87l5.739 1.811s.151 5.733-4.229 12.221c-.302.453-1.057.453-1.359 0l-3.323-5.13c-.453-.604-.453-1.358-.151-1.961l3.323-6.941z"
        fill="#fff"
      />
      <path
        d="M299.566 149.754c-1.963-9.505-24.769-6.186-6.041-31.081.453-.603 1.359-.905 2.114-.603 1.964 1.056 7.25.452 10.421 2.263 5.891 3.319 16.01 13.73 14.952 20.369-2.416 14.182 8.609 23.235 12.536 37.87 3.776 14.937-17.671 35.155-30.508 33.496-4.531-.604-7.703-3.169-9.666-6.639-1.51-2.565-1.964-5.884-1.057-8.751 2.567-9.506 13.592-15.39 7.249-46.924z"
        fill="#131313"
      />
      <path
        d="M338.231 269.099c-.454-2.112-.756-4.224-1.058-4.979-.151-.301-.755-1.207-.906-1.358-.453-.754-1.208-1.81-1.208-2.564v-3.622c0-1.659-.906-3.017-2.568-3.017-1.812 0-2.718 1.66-2.567 3.47v2.414c.151 1.509-.302 2.867-.755 4.376-.906 2.866-.151 6.337.755 9.052.604 1.66 4.531 1.358 7.702 1.057 2.115-.151 4.682-.906 4.229-1.811-.755-1.509-8.457.453-7.249-4.074 0-.151.302-.754.604-.754.906 0 1.057 1.81 1.359 2.867.604 1.357 1.511 1.81 2.115 1.659.604-.452-.151-1.207-.453-2.716z"
        fill="#FAB9AF"
      />
      <path
        d="M351.369 155.185s-27.639 37.117-27.639 57.938c0 14.183 5.89 45.113 5.89 45.113s1.511.302 2.87.302c1.359 0 2.87-.302 2.87-.302s-.151-39.53 2.114-46.622c2.416-7.091 25.373-40.737 25.826-48.13.604-7.393-7.249-14.183-11.931-8.299z"
        fill="#6469D4"
      />
      <path
        d="M307.268 150.207a28.784 28.784 0 00-10.27-1.358c-.755-.151-1.661 0-2.416.151 0 0-10.421 0-37.909 20.52-.755.603-1.661.754-2.567.603l-35.493-7.091c-1.963-.453-3.926 1.056-3.775 3.017v.151c0 1.207.906 2.263 1.963 2.716 6.192 2.565 30.961 12.372 43.95 13.127 4.833.301 10.421-1.057 15.858-3.32 2.417-.905 4.833 1.056 4.38 3.47-1.208 7.846-2.87 15.994-4.38 22.18-4.078 15.691-19.03 51.449-14.952 54.467 4.078 3.169 27.488 2.565 30.357-.453 2.87-3.168 24.165-105.464 15.254-108.18z"
        fill="#D29370"
      />
      <path
        d="M292.316 216.141c-6.494.755-11.629 5.583-12.686 11.92-2.266 14.333-4.682 41.34 1.057 73.478l9.515 71.366c1.812.603 3.474.301 5.286-.604 0 0 7.25-104.257 11.478-125.984 4.229-21.727.453-31.685.453-31.685l-15.103 1.509z"
        fill="#007B40"
      />
      <path
        d="M291.712 301.689c1.964-28.968 14.197-47.225 9.213-85.699v-.604l6.646-.452s3.926 9.958-.453 31.684c-3.625 18.106-9.213 93.998-11.026 118.29-.755-35.306-5.286-49.338-4.38-63.219z"
        fill="#005A2F"
      />
      <path
        d="M312.857 214.331c7.249-.302 13.895 4.225 16.009 11.316 3.172 10.411 6.192 27.913 3.323 53.411 0 0-2.115 13.881 0 22.783 2.114 8.902 15.707 69.103 15.707 69.103-1.359 1.358-2.87 2.263-4.682 2.263 0 0-24.165-49.187-30.206-66.84-6.041-17.803-16.613-74.534-14.499-90.678l14.348-1.358z"
        fill="#007B40"
      />
      <path
        d="M325.846 277.549c1.812 4.376 3.625 8.751 5.135 13.127.151-6.337.906-11.769.906-11.769 2.87-25.498-.151-43-3.322-53.411-2.115-6.94-8.609-11.618-16.01-11.316l-5.286.453-9.213.754v.151l-6.041.603c-6.494.755-11.629 5.583-12.686 11.92-.302 2.263-.756 4.979-1.058 7.846 24.165-.755 38.966 21.123 47.575 41.642z"
        fill="#005A2F"
      />
      <path
        d="M281.895 177.517c.151-3.621 4.078-13.277 7.25-17.653 0 0 5.588-8.751 11.629-9.656 6.948-1.207 13.895-1.207 20.54.151l5.891 1.207c4.833 1.509 2.567 7.091 1.661 12.07l-5.135 56.127c-9.062 6.488-26.733 8.449-42.44 6.79-1.661-.151-2.718-1.811-2.265-3.32l8.307-28.063c1.812-6.79-.605-8.148-3.172-11.316-1.51-1.811-2.417-4.074-2.266-6.337z"
        fill="#fff"
      />
      <path
        d="M308.778 113.693c7.552.453 10.119 6.036 9.666 15.994-.151 3.772-2.114 7.393-3.625 9.656-1.057 1.659-1.51 3.772-1.057 5.733l1.359 5.734c.756 5.28-1.812 7.996-5.588 8.751-1.812.452-4.078-.151-5.588-1.207-1.812-1.358-1.812-3.923-1.359-6.186l.604-1.509c.755-2.113-.453-4.527-2.567-5.13-1.662-.453-3.474-.302-4.682-1.207-1.209-.905-3.625-10.26-1.662-19.614 2.115-9.808 6.948-11.467 14.499-11.015z"
        fill="#FAB9AF"
      />
      <path
        d="M314.669 131.196c-.151 2.263-.302 5.281 1.813 5.281 1.963.151 4.38-3.923 4.531-6.337.151-2.263-1.057-3.018-3.172-3.018-2.114-.151-3.02 1.66-3.172 4.074z"
        fill="#FAB9AF"
      />
      <path
        d="M313.008 167.407c-.604 11.92-3.776 24.292 3.474 44.962 5.286 14.937-8.005 31.534-3.323 46.471 1.963 6.337 23.712 6.639 31.263 1.358 2.115-1.358-6.343-41.643-13.139-55.222-5.438-10.863-.303-30.628 2.114-38.323.906-3.169.906-6.488-.151-9.656-1.057-2.565-2.719-5.13-6.041-5.583-11.932-1.961-13.895 11.165-14.197 15.993z"
        fill="#BD7E5B"
      />
      <path
        d="M274.194 365.714c-.604 4.526-4.531 7.997-9.062 7.997H218.01c-5.588 0-9.968-4.979-9.062-10.562l13.593-131.865s30.961-8.751 37.305-1.811c20.691 22.632 16.764 116.627 14.348 136.241z"
        fill="#007B40"
      />
      <path
        d="M259.845 224.138c-6.343-6.941-37.304 1.81-37.304 1.81l-.897 12.222c55.58.15 38.805 124.57 30.348 139.809h13.139c4.682 0 8.458-3.47 9.062-7.996 2.417-19.615 6.343-123.213-14.348-145.845z"
        fill="#005A2F"
      />
      <path
        d="M240.513 146.586c9.593 0 17.369-6.755 17.369-15.088 0-8.333-7.776-15.088-17.369-15.088-9.592 0-17.368 6.755-17.368 15.088 0 8.333 7.776 15.088 17.368 15.088z"
        fill="#2F2F2F"
      />
      <path
        d="M246.705 231.078l-11.025.302c-7.4.151-13.895-1.358-14.65-8.751l-10.572-54.015c-.151-3.923 3.021-7.695 6.343-9.505 0 0 30.508-5.583 42.289 1.056l.906.301c3.172 1.811 5.588 5.432 5.588 9.053l-5.437 52.506c-.604 7.242-6.343 8.902-13.442 9.053z"
        fill="#D29370"
      />
      <path
        d="M263.923 163.937c-.453.754-.906 1.509-1.359 2.112a32.503 32.503 0 00-6.042 18.106c-.151 5.733.453 11.617-1.208 15.389-2.114 4.527-9.817 4.527-13.593 1.207 0 0-.151 0-.151-.151-2.265-1.961-5.437-2.263-7.702-.301-1.964 1.659-4.682 2.866-8.005 2.866-4.078 0-7.249-1.659-9.213-4.073l4.531 23.386c.755 7.393 7.25 8.902 14.65 8.751l11.025-.302c7.099-.151 12.838-1.81 13.593-9.053l5.588-52.506c-.151-1.81-.906-3.772-2.114-5.431z"
        fill="#BD7E5B"
      />
      <path
        d="M226.014 176.309c-3.021 9.506-20.691 46.471-26.279 44.66-5.588-1.81 4.38-38.474 7.4-47.979 3.021-9.505 8.005-15.842 13.593-14.032 5.588 1.811 8.307 7.846 5.286 17.351zM248.972 176.46c1.661 9.807 15.254 47.98 20.993 47.075 5.739-1.057-.151-37.871-1.812-47.678-1.662-9.807-5.739-16.748-11.478-15.692-5.891.906-9.364 6.337-7.703 16.295z"
        fill="#D29370"
      />
      <path
        d="M263.923 212.822c-6.343-1.207-47.121-6.94-47.876-3.47-.756 3.47 38.965 14.937 45.309 16.295 6.343 1.207 9.968-1.056 10.723-4.677.604-3.471-1.812-6.79-8.156-8.148z"
        fill="#EDB0A6"
      />
      <path
        d="M246.857 142.21h-14.348c.756 1.962 1.058 4.225.605 6.337l-1.36 7.393c-.906 6.79 1.964 10.26 5.89 11.316 1.964.453 4.229-.15 5.74-1.659 1.812-1.66 1.812-5.13 1.359-7.846l-.604-1.961c-.604-2.867 2.718-13.58 2.718-13.58z"
        fill="#FAB9AF"
      />
      <path
        d="M246.857 142.21h-14.348c.755 1.962 1.057 4.225.604 6.337l-.453 2.716c1.661 1.962 3.474 3.621 4.833 4.527 1.661 1.207 3.776 1.207 5.437-.151.302-.302.755-.604 1.057-.906.151-3.772 2.87-12.523 2.87-12.523zM230.244 132.554c.151 2.263.302 4.979-1.662 5.13-1.963.151-4.229-3.772-4.38-6.035-.151-2.263 1.058-2.867 3.021-3.018 1.963-.151 2.87 1.66 3.021 3.923zM250.179 132.554c-.151 2.263-.302 4.979 1.662 5.13 1.963.151 4.229-3.772 4.38-6.035.151-2.263-1.058-2.867-3.021-3.018-2.114-.151-2.87 1.66-3.021 3.923z"
        fill="#FA968C"
      />
      <path
        d="M253.351 132.554c0 9.807-6.494 16.597-10.27 19.765-1.662 1.358-3.776 1.358-5.437.151-3.927-2.866-10.421-9.203-10.421-19.765 0-11.618 6.343-16.295 13.139-16.295 6.948 0 12.989 4.527 12.989 16.144z"
        fill="#FAB9AF"
      />
      <path
        d="M255.163 117.768a.96.96 0 010-.905c.756-1.057 1.36-2.414 1.36-3.923 0-1.962-.906-3.772-2.266-4.979-.906-.755-1.208-1.811-.906-2.867.151-.754.151-1.66-.151-2.716-.604-1.961-2.265-3.47-4.229-3.923a5.723 5.723 0 00-2.265 0c-1.36.302-3.021 0-4.078-.905-1.057-.905-2.417-1.509-3.927-1.509-1.812 0-3.474.906-4.682 2.414-1.057 1.207-1.359 2.414-1.359 3.471 0 1.056-.302 1.961-1.057 2.715-.906.906-1.511 2.113-1.511 3.471 0 .905.302 1.659.605 2.263.755 1.358.302 3.168-.907 4.224-.604.453-1.057.755-1.661 1.358-4.531 4.225-3.776 12.523-3.776 12.523 1.813.453 3.021 1.962 3.021 3.772v3.621c0 .302.302.604.604.604s.604-.302.604-.604l.151-2.414a5.798 5.798 0 014.38-5.28c1.51-.453 2.87-1.057 4.078-2.264 1.208-1.056 2.114-2.112 2.869-3.319 1.209-1.66 3.625-2.565 5.438-1.811 2.416 1.057 4.228 3.018 5.437 5.13.906 1.66 1.51 3.621 1.51 5.583v4.677c0 .302.302.604.604.604s.604-.302.604-.604v-2.414c0-.754.302-1.66.755-2.263 2.417-3.169 4.078-9.506.755-13.73z"
        fill="#2F2F2F"
      />
      <path
        d="M258.485 211.917s-1.057-3.622-2.718-5.583c-1.208-1.358-5.89-.151-8.76.604-1.359.452-2.87.603-4.38.452-10.723-.603-31.112-.302-35.945.755-6.343 1.207-9.515 6.337-8.76 9.807 1.208 5.582 4.682 6.94 11.025 5.733 4.531-.905 23.41-6.94 33.68-11.467 2.719-1.207 5.588-1.659 8.609-1.207l7.249.906z"
        fill="#FAB9AF"
      />
      <path
        d="M319.653 118.673c-8.156-9.958-19.785-9.506-25.222-4.225-2.266 2.113-5.135 3.47-8.156 3.621a1.368 1.368 0 00-1.057 2.264c1.661 2.112 4.833 4.526 9.364 3.771 1.661-.301 3.172-1.056 4.38-2.263.755-.603 1.661-1.056 2.567-1.207 1.964-.151 3.927.604 5.437 1.811.907.754 1.964 1.358 3.172 1.659l1.51.302c1.209.302 1.964 1.358 1.813 2.565l-.453 4.677c0 .755.906 1.207 1.51.755.604-1.962 1.963-3.47 3.625-4.376 3.02-1.508 6.192-3.621 1.51-9.354z"
        fill="#2F2F2F"
      />
      <path
        d="M316.934 267.591c0-2.112-.604-4.828-.604-5.582 0-.302.604-3.32 1.057-5.583a9.14 9.14 0 012.115-4.375c.755-.906 1.661-1.66 2.416-1.962 0 0 2.719.905 2.87 2.867l-2.115 3.319c.152 1.509 1.058 2.716 1.813 4.074 1.51 2.716.302 6.488 0 9.354-.151 1.811-3.776 8.45-4.531 7.695-.755-.754.604-3.621 1.057-5.431.453-1.66-.151-4.527-1.057-6.186-.151-.151-.453-.755-.755-.604-.906.151-.604 2.112-.755 3.168-.302 1.358-1.058 2.113-1.662 2.113-.302-.302.151-1.358.151-2.867z"
        fill="#FAB9AF"
      />
      <path
        d="M317.841 159.712s.906 12.222 9.515 46.622l-9.666 46.018c.604 2.565 5.286 3.772 7.098 2.716 0 0 12.234-33.646 15.103-45.415 3.474-14.484-2.718-49.035-7.249-55.221s-16.161-2.716-14.801 5.28z"
        fill="#D29370"
      />
    </g>
    <path
      d="M341.037 33.107V18.19C341.037 8.186 332.846 0 322.835 0h-161.09c-10.011 0-18.202 8.186-18.202 18.19V382.43c0 10.005 12.745 16.673 22.756 16.673h152.232c11.649 0 21.562-8.697 21.658-17.281.096-8.583.848-236.993.848-348.714zm-6.007 325.612c0 4.002-3.276 7.276-7.281 7.276H155.193c-4.005 0-7.281-3.274-7.281-7.276V40.383c0-4.002 3.276-7.276 7.281-7.276h172.556c4.005 0 7.281 3.274 7.281 7.276V358.72z"
      fill="#fff"
    />
    <g opacity={0.25}>
      <path
        opacity={0.6}
        d="M271.687 33.107L147.912 222.653V117.148l54.788-84.04h68.987z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        opacity={0.6}
        d="M323.927 33.107L147.912 302.51v-48.933l143.979-220.47h32.036z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        opacity={0.6}
        d="M335.031 143.342v48.933l-113.582 173.72h-31.854l145.436-222.653z"
        fill="url(#prefix__paint2_linear)"
      />
    </g>
    <path
      d="M216.233 16.553c0 1.455-1.274 2.729-2.731 2.729-1.456 0-2.73-1.274-2.73-2.729s1.274-2.729 2.73-2.729c1.457 0 2.731 1.274 2.731 2.73zM272.484 19.207h-47.535c-1.28 0-2.377-.842-2.377-1.825v-1.685c0-.983 1.097-1.825 2.377-1.825h47.535c1.28 0 2.377.842 2.377 1.825v1.545c0 1.123-1.097 1.965-2.377 1.965zM241.968 393.281c6.031 0 10.921-4.886 10.921-10.914s-4.89-10.914-10.921-10.914c-6.032 0-10.922 4.886-10.922 10.914s4.89 10.914 10.922 10.914z"
      fill="#DCDCDC"
    />
    <path
      d="M473.218 99.562H367.63c-5.786 0-10.487 4.694-10.487 10.471v57.05c0 5.777 4.701 10.471 10.487 10.471h5.785v25.997l26.036-25.997h73.767c5.786 0 10.487-4.694 10.487-10.471v-57.05c0-5.777-4.701-10.47-10.487-10.47z"
      fill="url(#prefix__paint3_linear)"
      fillOpacity={0.9}
    />
    <path
      d="M382.456 155.168h24.951c1.808 0 3.616 1.444 3.616 3.61 0 1.806-1.447 3.611-3.616 3.611h-24.951c-1.808 0-3.616-1.444-3.616-3.611 0-1.805 1.446-3.61 3.616-3.61zM382.456 137.837h45.924c1.808 0 3.616 1.444 3.616 3.611 0 1.805-1.447 3.611-3.616 3.611h-45.924c-1.808 0-3.616-1.445-3.616-3.611 0-1.805 1.446-3.611 3.616-3.611zM382.456 120.866h78.83c1.808 0 3.616 1.444 3.616 3.61 0 1.806-1.446 3.611-3.616 3.611h-78.83c-1.808 0-3.616-1.444-3.616-3.611 0-1.805 1.446-3.61 3.616-3.61z"
      fill="#00C366"
    />
    <path
      d="M8.658 167.062h109.247c4.776 0 8.657 4.984 8.657 11.118v60.576c0 6.135-3.881 11.119-8.657 11.119h-4.777v27.604l-21.495-27.604H8.659C3.88 249.875 0 244.891 0 238.756V178.18c0-6.134 3.881-11.118 8.658-11.118z"
      fill="url(#prefix__paint4_linear)"
    />
    <path
      d="M20.208 226.104H47.94c2.01 0 4.02 1.534 4.02 3.834 0 1.917-1.608 3.834-4.02 3.834H20.208c-2.01 0-4.02-1.534-4.02-3.834 0-1.917 1.608-3.834 4.02-3.834zM20.208 207.702h51.044c2.01 0 4.02 1.533 4.02 3.834 0 1.917-1.609 3.834-4.02 3.834H20.208c-2.01 0-4.02-1.534-4.02-3.834 0-1.917 1.608-3.834 4.02-3.834zM20.208 189.681h87.619c2.009 0 4.019 1.534 4.019 3.834 0 1.917-1.608 3.834-4.019 3.834h-87.62c-2.009 0-4.018-1.534-4.018-3.834 0-1.917 1.607-3.834 4.019-3.834z"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={209.799}
        y1={33.107}
        x2={209.799}
        y2={222.653}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.906} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="prefix__paint1_linear"
        x1={235.919}
        y1={33.107}
        x2={235.919}
        y2={302.51}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.906} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="prefix__paint2_linear"
        x1={262.313}
        y1={143.342}
        x2={262.313}
        y2={365.995}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={0.906} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="prefix__paint3_linear"
        x1={356.932}
        y1={99.31}
        x2={516.113}
        y2={274.696}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#fff" />
        <stop offset={1} stopColor="#fff" stopOpacity={0} />
      </linearGradient>
      <linearGradient
        id="prefix__paint4_linear"
        x1={74.319}
        y1={167.062}
        x2={74.319}
        y2={277.479}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0DD676" />
        <stop offset={1} stopColor="#7DE1B1" stopOpacity={0.51} />
      </linearGradient>
    </defs>
  </svg>
)

export default SvgComponent
