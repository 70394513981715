import React from 'react'

const SvgComponent = props => (
  <svg width={60} height={60} fill="none" {...props}>
    <g clipPath="url(#instagram_logo__clip0)">
      <path
        d="M42.125 0h-24.25C8.017 0 0 8.018 0 17.874v24.252C0 51.982 8.018 60 17.874 60h24.251C51.981 60 60 51.982 60 42.126V17.874C60 8.018 51.98 0 42.125 0zm11.839 42.126c0 6.538-5.3 11.838-11.839 11.838h-24.25c-6.539 0-11.84-5.3-11.84-11.838V17.874c0-6.538 5.301-11.838 11.84-11.838h24.25c6.538 0 11.839 5.3 11.839 11.838v24.252z"
        fill="url(#instagram_logo__paint0_linear)"
      />
      <path
        d="M30 14.482c-8.557 0-15.518 6.961-15.518 15.518S21.443 45.518 30 45.518c8.556 0 15.518-6.961 15.518-15.518S38.556 14.482 30 14.482zm0 25a9.482 9.482 0 1 1 0-18.964 9.482 9.482 0 0 1 0 18.964z"
        fill="url(#instagram_logo__paint1_linear)"
      />
      <path
        d="M45.548 18.317a3.718 3.718 0 1 0 0-7.437 3.718 3.718 0 0 0 0 7.437z"
        fill="url(#instagram_logo__paint2_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="instagram_logo__paint0_linear"
        x1={30}
        y1={59.825}
        x2={30}
        y2={0.466}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E09B3D" />
        <stop offset={0.3} stopColor="#C74C4D" />
        <stop offset={0.6} stopColor="#C21975" />
        <stop offset={1} stopColor="#7024C4" />
      </linearGradient>
      <linearGradient
        id="instagram_logo__paint1_linear"
        x1={30}
        y1={59.825}
        x2={30}
        y2={0.466}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E09B3D" />
        <stop offset={0.3} stopColor="#C74C4D" />
        <stop offset={0.6} stopColor="#C21975" />
        <stop offset={1} stopColor="#7024C4" />
      </linearGradient>
      <linearGradient
        id="instagram_logo__paint2_linear"
        x1={45.548}
        y1={59.825}
        x2={45.548}
        y2={0.466}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E09B3D" />
        <stop offset={0.3} stopColor="#C74C4D" />
        <stop offset={0.6} stopColor="#C21975" />
        <stop offset={1} stopColor="#7024C4" />
      </linearGradient>
      <clipPath id="instagram_logo__clip0">
        <path fill="#fff" d="M0 0h60v60H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgComponent
