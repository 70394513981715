import React from 'react'
import styled from 'styled-components'

import { media } from '~/helpers';

const Container = styled.div.attrs(({ disabled, reversed }) => ({
  disabledStyles: disabled && `
    opacity: 0.6;
    pointer-events: none;
  `,
  flexDirection: reversed ? 'row-reverse' : 'row'
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${p => p.flexDirection};

  margin: 2em 5vw;

  ${p => p.disabledStyles};

  ${media.tablet`
    // flex-direction: column;
    margin: 2em;
  `}

  ${media.phone`
    flex-direction: column;
    margin: 3em 2em;
  `}
`;

const TextBlock = styled.div`
  width: 50%;
  padding-left: ${p => p.reversed ? 50 : 0}px;
  padding-right: ${p => !p.reversed ? 50 : 0}px;

  ${media.tablet`
    padding: 0;
  `}

  ${media.phone`
    width: auto;
  `}
`;

const P = styled.p`
  max-width: 800px;
  text-align: left;
  margin: 0;
  color: white;
  font-weight: bold;

  // text-align: ${p => !p.reversed ? 'right' : 'left'}

  ${media.phone`
    text-align: center;
  `}
`;

const Title = styled(P)`
  font-size: 48px;
  margin-bottom: 16px;
`;

const Subtitle = styled(P)`
  font-size: 18px;
  line-height: 21px;
  margin: 9px 0 35px 0;
`;

const Text = styled(P)`
  font-size: 18px;
  line-height: 1.4em;
  font-weight: normal;
`;

const Picture = styled.div.attrs(({ size = 24 }) => ({
  size
}))`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 3em;

  border-radius: 50%;

  min-width: ${p => p.size}em;
  max-width: ${p => p.size}em;
  min-height: ${p => p.size}em;
  max-height: ${p => p.size}em;

  background-color: #00A355;

  ${media.tablet`
    min-width: ${p => p.size * 0.6}em;
    max-width: ${p => p.size * 0.6}em;
    min-height: ${p => p.size * 0.6}em;
    max-height: ${p => p.size * 0.6}em;
  `}

  ${media.phone`
  min-width: ${p => p.size * 0.8}em;
  max-width: ${p => p.size * 0.8}em;
  min-height: ${p => p.size * 0.8}em;
  max-height: ${p => p.size * 0.8}em;
  `}
`;

const FeatureView = ({ disabled, title, subtitle, text, reversed, image, pictureProps }) => (
  <Container reversed={reversed} disabled={disabled}>
    <TextBlock reversed={reversed}>
      <Title reversed={reversed}>{title}</Title>
      {subtitle && <Subtitle reversed={reversed}>{subtitle}</Subtitle>}
      <Text reversed={reversed}>{text}</Text>
    </TextBlock>
    <Picture {...pictureProps}>
      {image}
    </Picture>
  </Container>
)

export default FeatureView;
