import React from 'react'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import '~/utils/analytics'

import App from '../app'

const GlobalStyle = createGlobalStyle`
  html, body {
    background-color: ${p => p.theme.bg};
    margin: 0;
    height: 100%;
    position: relative;
    font-family: 'Roboto', sans-serif;
  }
`;

const theme = {
  bg: '#00C366'
}

const Root = () => (
  <ThemeProvider theme={theme}>
    <>
      <App />
      <GlobalStyle />
    </>
  </ThemeProvider>
);

export default Root
