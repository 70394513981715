import { css } from 'styled-components'

const sizes = {
  desktop: 992,
  tablet: 768,
  phone: 576,
}

export const media = Object.keys(sizes)
  .map(key => ({ key, value: `${sizes[key] / 16}em` }))
  .reduce((res, { key, value }) => {
    res[key] = (...args) => css`
      @media (max-width: ${value}) {
        ${css(...args)}
      }
    `
    return res
  }, {})
