import React from 'react'

import FeatureView from './feature-view'
import TimelyPayment from '../assets/images/timely-payment'
import AvoidFees from '../assets/images/avoid-fees'
import Consultation from '../assets/images/consultation'

const Features = () => (
  <>
    <FeatureView
      title='Своєчасні оповіщення'
      text='Мобільний сервіс допомагає та полегшує контроль над
      вашими податками. Ви завжди отримуєте своєчасні оповіщення
      щодо дати подання звітності та сплати податків, де би ви
      не знаходились.'
      image={<TimelyPayment
        width={380}
        // style={{ position: 'absolute' }}
      />}
    />
    <FeatureView
      reversed
      title='Уникнення штрафів'
      text='З мобільним додатком FeeTime ви можете
      уникнути небажаних штрафів. Ви завжди будете проінформовані
      про дати та типи операцій. Заощаджуйте ваші кошти
      з нашою допомогою.'
      image={<AvoidFees
        width={400}
        style={{ top: '-12px', left: '-22px' }}
      />}
    />
    <FeatureView
      disabled
      title='On-line Консультаціі&#32;(невдовзі)'
      text='Наші досвідченні юристи та бухгалтері - завжди дадуть вам
      професійну та кваліфіковану відповідь у разі виникнення у вас
      питань чи проблем.'
      image={<Consultation
        width={480}
        // style={{ top: '-36px', left: '-63px' }}
      />}
    />
  </>
)

export default Features
