import React from 'react'
import styled from 'styled-components'

import AppStore from '~/assets/icons/app-store.svg'

const Link = styled.a`
  padding: 10px;
  margin: 10px;
`

export default ({ imgProps }) => (
  <Link href='https://itunes.apple.com/app/id1462212346' target='_blank'>
    <img src={AppStore} alt='download FeeTime on App Store' {...imgProps} />
  </Link>
);
