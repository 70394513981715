import React from 'react'
import styled from 'styled-components'

import FBLikeButton from '~/app/partials/buttons/fb-like'
import Twitter from './assets/icons/twitter'
import Instagram from './assets/icons/instagram'
import Facebook from './assets/icons/facebook'

const Link = styled.a`
  margin: 0 30px;
`

const SocialGroup = styled.div`
  display: flex;
  align-items: center;
  // position: absolute;
  // top: calc(50% - 32px);

  height: 60px;

  > ${Link} {
    &:nth-child(even) {
      // margin-left: 0px;
    }
  }
`
const Footer = styled.div`
  position: relative;
  height: 316px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  background-color: white;
`

const Text = styled.p`
  // position: absolute;
  // bottom: 0;
  color: #626262;

  font-size: 14px;
  line-height: 16px;

  margin: 0;
  // margin-bottom: 60px;
`;

export default () => (
  <Footer id='footer'>
    {/* <Footer> */}
      <SocialGroup>
        <Link href='https://facebook.com/feetimeco' target='_blank'><Facebook /></Link>
        <Link href='https://instagram.com/feetimeco' target='_blank'><Instagram /></Link>
        <Link href='https://twitter.com/feetimeco' target='_blank'><Twitter /></Link>
      </SocialGroup>
      <Text>Copyright © 2019 FeeTime. All rights reserved.</Text>
    {/* </Footer> */}

    <FBLikeButton />
  </Footer>
)
