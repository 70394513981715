import React from 'react'
import styled from 'styled-components'

import { media } from '~/helpers';

import AppStoreButton from '~/app/partials/buttons/app-store'
import QRCode from '~/assets/images/qr-code'

const P = styled.p`
  text-align: left;
  margin: 0;
  color: white;
  font-weight: bold;

  text-align: center;
`

const Title = styled(P)`
  font-size: 48px;
  line-height: 56px;
  margin-bottom: 77px;

  ${media.phone`
    font-size: 32px;
    line-height: 37px;
    margin: 0 20px 77px;
  `}
`

const QRContainer = styled.div`
  border-radius: 12px;
  background: white;
  padding: 17px;
  width: 136px;
  height: 136px;

  ${media.phone`
    display: none;
  `}
`

function DownloadSection() {
  return (
    <>
      <Title>Завантажити додаток FeeTime</Title>
      <AppStoreButton imgProps={{ width: '210' }} />
      <QRContainer>
        <QRCode />
      </QRContainer>
    </>
  )
}

export default DownloadSection
