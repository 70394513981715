import React from 'react'
import styled from 'styled-components'

import { media } from '~/helpers';

import { scrollHREFNavigation } from '../../helpers/scroll';

import Logo from './assets/icons/logo'

const Container = styled.div`
  display: flex;
  margin: 30px 40px;
  align-items: center;
  justify-content: space-between;

  ${media.phone`
    align-items: initial;
    flex-direction: column;
  `}
`;

const Menu = styled.div`
  display: flex;

  p {
    margin-right: 30px;

    > &:last-child {
      margin-right: 0px;
    }
  }

  ${media.phone`
    padding: 20px 0;
    flex-direction: column;

    p {
      margin-right: 0px;
    }
  `}
`;

const Link = styled.a`
  text-decoration: none;

  ${media.phone`
    text-align: center;
    padding: 10px;
    // margin-bottom: 2px;
  `}
`

const Text = styled.p`
  margin: 0;
  font-size: 18px;
  line-height: 21px;

  color: white;
`;

export default () => (
  <Container>
    <Logo />
    <Menu>
      <Link href='https://drive.google.com/drive/folders/1HYxSd7aRoIE2iQOYjL5s5Qgyq1RFYBkR' target='_blank'><Text>Медіа-кіт</Text></Link>
      <Link href='#features' onClick={scrollHREFNavigation}><Text>Особливості</Text></Link>
      <Link href='#footer' onClick={scrollHREFNavigation}><Text>Контакти</Text></Link>
    </Menu>
  </Container>
)
