import React from 'react'

const SvgComponent = props => (
  <svg width={60} height={60} fill="none" {...props}>
    <path
      d="M51.914 0H8.085A8.085 8.085 0 0 0 0 8.085v43.83A8.085 8.085 0 0 0 8.085 60h21.617l.036-21.44h-5.57a1.314 1.314 0 0 1-1.314-1.31l-.027-6.911a1.314 1.314 0 0 1 1.315-1.32h5.56v-6.678c0-7.75 4.733-11.97 11.646-11.97h5.673c.726 0 1.314.59 1.314 1.315v5.828c0 .725-.588 1.314-1.313 1.314l-3.482.002c-3.76 0-4.487 1.786-4.487 4.408v5.781h8.261c.787 0 1.398.688 1.305 1.47l-.819 6.91a1.314 1.314 0 0 1-1.305 1.16H39.09L39.053 60h12.862A8.085 8.085 0 0 0 60 51.915V8.085A8.085 8.085 0 0 0 51.914 0z"
      fill="#4267B2"
    />
  </svg>
)

export default SvgComponent
