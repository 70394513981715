import React from 'react'
import styled from 'styled-components'

const LikeButton = styled.div`
  padding: 15px 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 100%;
  text-align: center;
`;

// only share
// export default () => (
//   <div className="fb-share-button"
//     data-href="https://feetime.co/"
//     data-layout="button_count">
//   </div>
// )

export default () => (
  <LikeButton
    className="fb-like"
    data-href="https://feetime.co/"
    data-width="320"
    data-layout="standard"
    data-action="like"
    data-size="small"
    data-show-faces="true"
    data-share="true">
  </LikeButton>
)
